// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("chartkick/chart.js")
//require("@rails/activestorage").start()
//require("channels")

import 'popper.js'
import 'bootstrap'

import '../theme/index'
// import '../theme/aos'
// import '../theme/jarallax'
// import '../theme/svg-injector'


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import ClipboardJS from "clipboard"

$(document).ready(() => {
  jQuery("pre").each(function() {
    var e = '<button class="btn btn-sm btn-primary btn-clipboard" type="button" title="Copy To Clipboard">Copy</button>';
    var wrap = '<div class="code-snippet">';
    jQuery(this).wrap(wrap).before(e);
  });
  jQuery(".btn-clipboard").tooltip().on("mouseleave", function() {
    jQuery(this).tooltip("hide")
  });
  $('body').tooltip({ selector: '[data-toggle="tooltip"]', container: 'body' });
  var e = new ClipboardJS(".btn-clipboard",{target:function(e){return e.nextElementSibling}});e.on("success",function(e){jQuery(e.trigger).attr("title","Copied!").tooltip("_fixTitle").tooltip("show").attr("title","Copy to clipboard").tooltip("_fixTitle");e.clearSelection()});e.on("error",function(e){var t=/Mac/i.test(navigator.userAgent)?"⌘":"Ctrl-";var n="Press "+t+"C to copy";jQuery(e.trigger).attr("title",n).tooltip("_fixTitle").tooltip("show").attr("title","Copy to clipboard").tooltip("_fixTitle")});
})

import "controllers"
